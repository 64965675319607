define("discourse/plugins/discourse-locations/discourse/components/replace-location", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/render-modifiers/modifiers/did-insert", "discourse-common/helpers/d-icon", "discourse/plugins/discourse-locations/discourse/components/user-location", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _didInsert, _dIcon, _userLocation, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ReplaceLocationComponent;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ReplaceLocationComponent = _exports.default = (_class = (_ReplaceLocationComponent = class ReplaceLocationComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    }
    get showUserLocation() {
      return !!this.args.model.custom_fields?.geo_location && this.args.model.custom_fields?.geo_location !== "{}";
    }
    get linkWebsite() {
      return !this.args.model.isBasic;
    }
    get removeNoFollow() {
      return this.args.model.trust_level > 2 && !this.siteSettings.tl3_links_no_follow;
    }
    addClass() {
      document.querySelectorAll(".user-main .location-and-website").forEach(function (element1) {
        element1.classList.add("map-location-enabled");
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.showUserLocation}}
        <div {{didInsert this.addClass}} class="user-profile-location">
          <UserLocation @user={{@model}} @formFactor="profile" />
        </div>
      {{/if}}
      {{#if @model.website_name}}
        <div class="user-profile-website">
          {{icon "globe"}}
          {{#if this.linkWebsite}}
            <a
              href={{@model.website}}
              rel={{unless this.removeNoFollow "nofollow ugc noopener"}}
              target="_blank"
            >
              {{@model.website_name}}
            </a>
          {{else}}
            <span title={{@model.website}}>{{@model.website_name}}</span>
          {{/if}}
        </div>
      {{/if}}
    
  */
  {
    "id": "SmaQ1NQp",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"showUserLocation\"]],[[[1,\"      \"],[11,0],[24,0,\"user-profile-location\"],[4,[32,0],[[30,0,[\"addClass\"]]],null],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@user\",\"@formFactor\"],[[30,1],\"profile\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[30,1,[\"website_name\"]],[[[1,\"      \"],[10,0],[14,0,\"user-profile-website\"],[12],[1,\"\\n        \"],[1,[28,[32,2],[\"globe\"],null]],[1,\"\\n\"],[41,[30,0,[\"linkWebsite\"]],[[[1,\"          \"],[10,3],[15,6,[30,1,[\"website\"]]],[15,\"rel\",[52,[51,[30,0,[\"removeNoFollow\"]]],\"nofollow ugc noopener\"]],[14,\"target\",\"_blank\"],[12],[1,\"\\n            \"],[1,[30,1,[\"website_name\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[15,\"title\",[30,1,[\"website\"]]],[12],[1,[30,1,[\"website_name\"]]],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@model\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-locations/discourse/components/replace-location.js",
    "scope": () => [_didInsert.default, _userLocation.default, _dIcon.default],
    "isStrictMode": true
  }), _ReplaceLocationComponent), _ReplaceLocationComponent), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addClass", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addClass"), _class.prototype)), _class);
});